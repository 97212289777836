<template>
  <v-list-item class="apListItem" v-on="on" @click="archive">
    <v-icon size="18" class="mr-2" small>$files</v-icon>
    <span> {{ $t("archivate", { name: $tc("") }) }}</span>
  </v-list-item>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "ArchivePack",
  props: ["pack"],
  methods: {
    ...mapActions("packs", ["archivePack"]),
    archive() {
      this.$confirm(
        this.$t("Estas seguro de que quieres eliminar este pack?")
      ).then(() => {
        this.archivePack(this.pack.id).then((response) => {
          if (response) {
            this.$emit("update");
            console.log("CORRECT");
            this.$alert(this.$t("pack_archived"));
          } else {
            console.log("ERROR");
          }
        });
      });
    },
  },
};
</script>
